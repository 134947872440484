.main-header {
  width: 100%;
}

.mh-top {
  padding: 1rem 0;
}

.mh-title {
  text-transform: uppercase;
}

.mh-top-container {
  display: flex;
  justify-content: center;
}
