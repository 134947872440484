.bg-primary {
  background-color: rgb(255, 255, 255);
}

.bg-secondary {
  background-color: rgb(233, 233, 233);
}

.bg-secondary.hover:hover {
  background-color: rgb(203, 203, 203);
}

.dark .bg-primary {
  background-color: rgb(42, 42, 42);
}

.dark .bg-secondary {
  background-color: rgb(104, 104, 104);
}

.dark .bg-secondary.hover:hover {
  background-color: rgb(158, 158, 158);
}

.dark {
  color: white !important;
}

html:has(.dark) {
  background-color: rgb(42, 42, 42);
}
