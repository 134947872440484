.main-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2rem 0;
}

.mf-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.mf-theme-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
}

.mf-theme-switch .mf-theme-button {
  transition: 0.3s;
  cursor: pointer;
}

.mf-theme-switch .mf-theme-button.dark-mode:hover {
  color: rgb(101, 137, 255);
}

.mf-theme-switch .mf-theme-button.light-mode:hover {
  color: rgb(240, 255, 143);
}
