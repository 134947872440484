.select {
  width: 100%;
  padding: 1rem;
  text-align: center;
}

.select:hover {
  cursor: pointer;
}

.s-selected {
  font-weight: 300;
  font-size: 1.4rem;
  text-transform: capitalize;
}

.s-selector {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  position: fixed;
  top: -100vh;
  left: 0;
  padding: 3rem 0;
  transition: 0.5s;
}

.s-selector.open {
  top: 0;
}

.ss-option {
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.ss-option:not(.selected):hover .ss-title {
  text-decoration: underline;
}

.ss-option.selected .ss-title {
  font-weight: 800;
}

.ss-title {
  width: 50%;
  font-size: 2.5rem;
  text-transform: capitalize;
}

.ss-items {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
}
